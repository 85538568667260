@use '../../../scss/breakpoint' as *;

.app {
  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    // background-color: var(--app-container);
    transition: 0.2s;

    button,
    input,
    optgroup,
    select,
    textarea {
      font-family: 'DM Sans', sans-serif;
    }
  }

  &-content {
    display: flex;
    overflow: hidden;
    height: calc(100vh - 41px);
    max-height: calc(100vh - 41px);
  }

  &-content--nofooter {
    display: flex;
    overflow: hidden;
    height: calc(100vh - 41px);
    max-height: calc(100vh - 41px);

    @include medium() {
      overflow: scroll;
    }
  }
}

.mode-switch {
  background-color: transparent;
  border: none;
  padding: 0;
  color: var(--main-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-wrapper {
  border-radius: 20px;
  background-color: var(--search-area-bg);
  padding-right: 12px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 480px;
  color: var(--light-font);
  box-shadow:
    0 2px 6px 0 rgba(136, 148, 171, 0.2),
    0 24px 20px -24px rgba(71, 82, 107, 0.1);
  overflow: hidden;

  .dark & {
    box-shadow: none;
  }
}

.search-input {
  border: none;
  flex: 1;
  outline: none;
  height: 100%;
  padding: 0 20px;
  font-size: 16px;
  background-color: var(--search-area-bg);
  color: var(--main-text-color);

  &:placeholder {
    color: var(--main-text-color);
    opacity: 0.6;
  }
}

.add-btn {
  color: #fff;
  background-color: var(--button-bg);
  padding: 0;
  border: 0;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-btn {
  color: var(--main-text-color);
  padding: 0;
  border: 0;
  background-color: transparent;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-content {
  flex: 1;
  width: 100%;
}

.mode-switch.active .moon {
  fill: var(--main-text-color);
}

.projects-section {
  flex: 1;
  background-color: var(--projects-section);
  // border-radius: 32px;
  // padding: 10px 32px 0 32px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include medium() {
    overflow: scroll;
  }

  &-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 32px;
  }
}
