@use '../../../../scss/breakpoint' as *;
@use '../../../../scss/inventory-common-variables' as *;
@use './../../../../layouts/Inventory/mixes';

.add-export-pricing-modal {
  .ant-modal-header {
    border: none !important;

    .ant-modal-title {
      background-image: none !important;
      color: #9e9b9b !important;
    }
  }

  .ant-pro-form {
    width: 100% !important;
    margin-top: 0;
    .ant-pro-form-group-container {
      width: 100%;

      .ant-space-item {
        width: inherit;

        .ant-select {
          .ant-select-selector {
            @include mixes.inventory-shadow-form-item();
          }
        }

        .ant-form-item-control {
          padding: 0.1rem 0.3rem !important;
        }

        .ant-picker {
          width: 100%;
          @include mixes.inventory-shadow-form-item();
        }

        .ant-form-item-label {
          width: 15rem;
          text-align: left;

          @include mobile() {
            text-align: left;
          }

          .ant-form-item-required {
            float: right;

            @include mobile() {
              float: left;
            }
          }
        }

        .ant-form-item-control {
          padding: 0 1rem;

          @include mobile() {
            padding: 0;
          }
        }

        label {
          color: #262626;
          line-height: 17px;

          &::after {
            content: '' !important;
          }

          &::before {
            content: '' !important;
          }

          @include mobile() {
            justify-content: flex-start;
          }
        }

        .ant-input-affix-wrapper {
          @include mixes.inventory-shadow-form-item();

          .ant-input {
            color: #0e0e0e !important;
          }
        }
      }

      .ant-form-item-control-input-content {
        display: flex !important;
        justify-content: flex-end !important;
      }

      .ant-radio-wrapper-in-form-item {
        width: 15rem;
        margin-bottom: 2rem;
        display: flex;
        position: relative;

        span {
          font-weight: bold;
        }

        .ant-radio {
          position: absolute;
          left: 0;
        }
      }
    }

    .ant-descriptions-item-container {
      .ant-descriptions-item-content {
        @include mobile() {
          display: block;
        }
      }
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
  }
}
