:root {
  --app-bg: #3d6a9e;

  --app-container: #3d6a9e;
  --main-text-color: #fff;
  --second-text-color: #1f1c2e;
  --secondary-color: #4a4a4a;
  --link-color: #1f1c2e;
  --link-color-hover: #c3cff4;
  --link-color-active: #fff;
  --link-color-active-bg: #1f1c2e;
  --projects-section: #fff;
  --line-color: rgba(5, 5, 5, 0.06);
  --room-bg: #fff;
  --scrollbar-bg: #fff;
  --scrollbar-thumb: #f5f5f5;
  --scrollbar-bg-rm: #fff;
  --transition-type: ease-in-out;
  --transtion-duration: 0.3s;
  --active-blue-color: #1677ff;
  --border-radius-8: 8px;
  --border-radius-4: 4px;

  --text-black: #1f1c2e;
  --text-white: #fff;
  --text-blue: #0c557c;
  --func-btn-color: #4096ff;
  --placeholder-color: #c5bfbf;
  --select-bg: transparent;
  --black-to-white: #fff;

  --disable-bg: rgba(0, 0, 0, 0.04);
  --disable-opacity: 1;

  --line-bg: #3d6ea9;

  --table-border: #f0f0f0;
}

.dark:root {
  --app-container: #111827;
  --room-bg: #f5f5f5;
  --main-text-color: #fff;
  --second-text-color: #1f1c2e;
  --secondary-color: #4a4a4a;
  --projects-section: #1f2937;
  --link-color: rgba(255, 255, 255, 0.8);
  --link-color-hover: rgba(195, 207, 244, 0.1);
  --link-color-active-bg: rgba(195, 207, 244, 0.2);
  --line-color: rgba(255, 255, 255, 0.2);
  --scrollbar-bg: #fff;
  --scrollbar-bg-rm: #1f2937;
  --scrollbar-thumb: #f5f5f5;

  --text-black: #fff;
  --text-blue: #fff;
  --func-btn-color: #fff;
  --func-btn-color: #4096ff;
  --select-bg: transparent;
  --black-to-white: #000;
  --footer-bg: #1f1c2e;

  --disable-bg: rgba(0, 0, 0, 0.3);
  --disable-opacity: 0.6;

  --line-bg: #fff;
  --text-white: #1f1c2e;
}
