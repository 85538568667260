.app-content-primary {
  // width: 100%;
  // height: calc(100% - 70px);
  // overflow-y: scroll;
  // overflow-x: auto;
  // min-height: calc(100% - 70px);
  // scroll-behavior: smooth !important;
  // padding: 0 15px 20px;
  &__floatbtn {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 25px;
    bottom: 20px;
    font-size: 14px;
    width: 40px;
    min-width: 40px;
    box-shadow:
      0 6px 16px 0 rgb(0 0 0 / 8%),
      0 3px 6px -4px rgb(0 0 0 / 12%),
      0 9px 28px 8px rgb(0 0 0 / 5%);
    border-radius: 8px;
    overflow: hidden;
    transition: background-color 0.2s ease-in-out;
    z-index: 99;
    & > div {
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid rgba(5, 5, 5, 0.06);
      color: rgba(0, 0, 0, 0.88);
      height: 40px;
      background-color: #fff;
      &:hover {
        background-color: #f5f5f5;
        cursor: pointer;
        & svg {
          transform: scale(1.1);
        }
      }
      & svg {
        object-fit: cover;
      }
    }
  }
  &__container {
    width: fit-content;
    height: fit-content;
    padding-top: 5px;
  }
}
