@use './breakpoint.scss' as *;
@use './variable.scss' as *;
//@import url('https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap'); enable when have network

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: 'DM Sans', sans-serif;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  // background-color: var(--app-container);
  font-size: 62.5%;
  margin: 0;
  width: 100vw;
  height: fit-content;
  scroll-behavior: smooth !important;

  @include tablet() {
    font-size: 60% !important;
  }

  transition: all 0.2s ease-in-out !important;
}

#root {
  width: 100vw;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  object-fit: cover;
}

button:hover {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  margin: 3px 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #eeecec;
}

.default-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--func-btn-color) !important;
  border-color: #4096ff !important;
  background-color: transparent !important;

  & > svg {
    width: 15px;
    height: 15px;
    object-fit: cover;
  }
}
.col-center {
  text-align: center !important;
}

.money-color {
  color: #ff5e1f !important;
  font-weight: 600;
}

.ellipsis-text {
  display: inline-block;
  max-width: 100%;
  vertical-align: bottom;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.88);
  word-break: break-word;
  line-height: 1.5;
  white-space: nowrap;
}

.highlight-search {
  font-weight: 600;
  background-color: #effd94;
}

.text-black {
  color: var(--text-black) !important;
}

.text-white {
  color: var(--text-white) !important;
}

.page-container {
  width: 100%;
  max-height: 100%;
  padding: 0 40px;
  background-color: var(--app-content-bg);

  @include tablet {
    padding: 0 15px;
  }

  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.text-line-through {
  text-decoration: line-through !important;
  font-style: italic !important;
}

.cs-btn {
  display: flex;
  align-items: center;
  gap: 5px;

  & > svg {
    width: 15px;
    height: 15px;
    object-fit: cover;
  }

  & .ant-btn-sm {
  }
}

.ant-btn-sm.cs-btn {
  & span {
    font-size: 1.2rem !important;
    margin-top: 1px;
  }
}

.input-number {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0;
  border-radius: 6px;
  padding: 0 3px;
  height: 30px;
  color: #3d6a9e;
  width: inherit;

  & > svg {
    transition: all 0.3s ease-in-out;
    font-size: 14px;
    cursor: pointer;
    min-width: 14px;

    &:hover {
      transform: translate(1.05);
    }
  }

  & > span {
    flex: 1;
    text-align: center;
    user-select: none;

    &::selection {
      background-color: #3d6a9e;
      color: #fff;
    }
  }

  background-color: #fff;

  & input {
    color: inherit;
    flex: 1;
    width: 100%;
    text-align: center !important;
    outline: none;
    border: none;
    border-radius: 6px;
  }

  & .ant-input-number-handler-wrap {
    display: none !important;
  }

  & .ant-input-number {
    border: none !important;
    background-color: transparent !important;
  }
}

.func-btn {
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}

.select-drowdown-300 {
  min-width: 300px !important;
  width: 300px !important;
}

.select-drowdown-200 {
  min-width: 200px !important;
  width: 200px !important;
}

.select-drowdown-400 {
  min-width: 400px !important;
  width: 400px !important;
}

.select-drowdown-500 {
  min-width: 500px !important;
  width: 500px !important;
}

.select-table-inner {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.cs-btn-pos {
  display: flex;
  align-items: center;
  justify-content: center !important;
  flex-direction: column !important;
  gap: 5px;
  height: 70px !important;
  width: 80px !important;
  padding: 0px 5px !important;

  & > svg {
    width: 20px !important;
    height: 20px !important;
    object-fit: cover;
  }

  & > span {
    font-size: 1.2rem;
  }

  & .ant-btn-loading-icon > .anticon {
    margin-inline-end: unset !important;
  }
}

.form-item {
  display: flex;
  align-items: center;
  gap: 10px;

  & > div {
    flex: 1;
    margin-bottom: 10px !important;

    & .ant-form-item-label {
      min-width: 100px;
    }
  }

  &--nested {
    & > div {
      margin-bottom: 0 !important;
    }
  }
}

.col-center {
  text-align: center !important;
}

.col-left {
  text-align: left !important;
}

.money-color {
  color: #ff5e1f !important;
  font-weight: 600;
}

.ellipsis-text {
  display: inline-block;
  max-width: 100%;
  vertical-align: bottom;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.88);
  word-break: break-word;
  line-height: 1.5;
  white-space: nowrap;
}

.cs-modal {
  & .ant-modal-footer {
    display: flex !important;
    justify-content: flex-end !important;
  }

  & .ant-modal-close-x {
    & svg {
      fill: #fff !important;
    }
  }

  & .ant-modal-title {
    color: #fff !important;
  }

  & .ant-modal-header {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--app-bg);
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    color: #fff !important;
    padding: 0 20px;
    border-radius: 6px 6px 0 0;
  }

  &__banner {
    position: absolute;
    top: 0;
    left: 0;
    height: 48px;
    width: 100%;
    border-radius: 6px 6px 0 0;
    z-index: 2;
    filter: grayscale(100%);
    background-size: contain;
  }

  & .ant-modal-content {
    // border: 1px solid var(--table-border);
    border-radius: 6px;
  }

  &__body {
    margin-top: 40px;
    // max-height: 80vh;
    height: fit-content;
  }
}

.cs-layout {
  padding: 7px 35px;
  width: 100%;
  height: 100%;
  background-color: #4371a3;

  @include tablet {
    padding: 7px;
  }

  &__main {
    width: 100%;
    height: 100%;
    background-color: #4371a3;
    padding: 0 15px 15px 15px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;

    @include tablet {
      padding: 0 10px;
    }

    &--empty {
      flex-direction: row;
      gap: 10px;
      background-color: var(--setting-page-bg);
      padding: 5px;

      @include tablet {
        // padding: 15px;
      }
    }
  }
}

.new-row {
  background-color: rgba(255, 255, 28, 0.1);
}

.edit-row {
  background-color: rgba(251, 0, 0, 0.063);
}

.group-txt {
  font-size: 1.4rem;
  font-weight: 600;
}

.group-row {
  background-color: #eeecec6b !important;

  & > td {
    border-inline-end: none !important;

    &.ant-table-cell-with-append {
      display: block !important;
    }
  }
}

.table-no-border {
  & .ant-table-container {
    border-inline-start: none !important;

    & .ant-table-header > table {
      border-top: none !important;
    }

    & .ant-table-content > table {
      border-top: none !important;
    }
  }
}

.badge-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.reason-modal {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 40px;

  & > span {
    min-width: 50px;
  }
}

@keyframes shakeUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  50% {
    transform: translateY(-50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.ant-tour {
  &.ant-tour-placement-bottom,
  &.ant-tour-placement-top {
    z-index: 9999 !important;
  }
}

.loading-bar {
  height: 5px;
  width: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: var(--header-height);
  z-index: 12;

  background-size: 500%;

  background: #3d6a9e - webkit-gradient(
      linear,
      left top,
      right top,
      from(#3d6a9e),
      color-stop(#3d6a9ec7),
      color-stop(#3d6a9e8e),
      color-stop(#3d6a9e47),
      to(#3d6a9e)
    );

  background: #3d6a9e - webkit-linear-gradient(
      left,
      #3d6a9e,
      #3d6a9ec7,
      #3d6a9e8e,
      #3d6a9e47,
      #3d6a9e
    );
  background: #3d6a9e
    linear-gradient(to right, #3d6a9e, #3d6a9ec7, #3d6a9e8e, #3d6a9e47, #3d6a9e);

  animation:
    2s linear infinite LoadingBarProgress,
    0.5s ease-out LoadingBarEnter;
  transform-origin: left;

  -webkit-transform-origin: left;
  -webkit-animation:
    2s linear infinite LoadingBarProgress,
    0.5s ease-out LoadingBarEnter;
}

@keyframes LoadingBarProgress {
  0% {
    background-position: 0% 0;
  }

  to {
    background-position: 125% 0;
  }
}

@keyframes LoadingBarEnter {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.ant-tour-close {
  opacity: 0;
}

.color-select {
  width: 42px;
  height: 42px;
  // width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  border-radius: 99px;

  &__inner {
    width: 100%;
    border-radius: 6px !important;
    height: 32px;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.03);
  }

  &__wrap {
    width: 300px;
  }
}

.money-color-input {
  & input {
    color: #ff5e1f !important;
  }
}

.white-text {
  color: #fff !important;
}

.floating {
  // background-color: inherit !important;
  // z-index: 2 !important;
}

.disable-area {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    cursor: not-allowed;
    user-select: none;
    z-index: 3;
  }
}

.disable-element {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    cursor: default;
    user-select: none;
    z-index: 3;
  }
  
  .btn-action {
    color: rgba(0, 0, 0, 0.25) !important;
    opacity: 1;
  }
}

.hidden {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-60px);
  // display: none !important;
}

.group-row {
  background-color: #eeecec6b !important;
  z-index: 2;

  & .ant-table-cell {
    background-color: inherit !important;
  }

  & > td {
    border-inline-end: none !important;

    &.ant-table-cell-with-append {
      display: block !important;
    }

    & .ant-table-row-expand-icon,
    & .ant-table-row-indent,
    & .ant-table-row-expand-icon {
      display: none !important;
    }
  }
}

.group-row-show-dropdown {
  background-color: #eeecec6b !important;
  z-index: 2;

  & .ant-table-cell {
    background-color: inherit !important;
  }

  & > td {
    border-inline-end: none !important;

    // &.ant-table-cell-with-append {
    //   display: block !important;
    // }

    // & .ant-table-row-expand-icon,
    // & .ant-table-row-indent,
    // & .ant-table-row-expand-icon {
    //   display: none !important;
    // }
  }
}

.group-child {
  & .ant-table-row-indent,
  & .ant-table-row-expand-icon {
    display: none !important;
  }
}

.table-no-border {
  & .ant-table-container {
    border-inline-start: none !important;
    border-top: none !important;

    & .ant-table-header > table {
      border-top: none !important;
    }

    & .ant-table-content > table {
      border-top: none !important;
    }
  }
}

.btn-action {
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
}

.money-color-input {
  & input {
    color: #ff5e1f !important;
    font-weight: 500 !important;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  gap: 5px;
}

.report-wrapper {
  max-height: 100%;
  overflow-y: scroll;
  padding: 12px 15px 15px;
  overflow-x: hidden;
}
