@use '../../../scss/index' as *;

.configuration-layout {
  background-color: white !important;

  .cs-layout {
    &__main {
      background-color: white;
    }
  }
}