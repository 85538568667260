@import '../scss/_common-variables.scss';
@import '../scss/base';
@import '../scss/common-layout';

@mixin menu {
  background-color: var(--header-bg) !important;
  color: var(--light-color) !important;

  &__item {
    a {
      color: var(--light-color);
    }

    .arrow-down-hover {
      display: none;
    }

    .arrow-down {
      display: inline;
      padding-left: 6px;
    }

    .ant-menu-item-icon {
      vertical-align: -0.6rem !important;
    }

    .ant-menu-title-content {
      &:hover {
        color: var(--light-color);
      }

      &:active {
        color: var(--light-color);
      }
    }
  }
}

/*.header {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    background-color: var(--header-bg) !important;
    height: 44px;
    line-height: 44px;

    &--menu-left {
        @include menu;

        &__item {
            padding-inline: 3px !important;
        }
    }

    &--menu-center {
        @include menu;

        &__item {
            padding-inline: 2px !important;

            &.date-time {
                margin-inline-start: 20px !important;
            }
        }
    }

    &--menu-right {
        @include menu;

        &__item {
            padding-inline: 5px !important;
        }

        .log-out {
            a:hover {
                color: var(--light-color);
            }
        }
    }
}*/

.main-content {
  //position: absolute;
  top: 44px;
  width: 100vw;
  min-height: calc(100vh - 44px);
  background-color: var(--light-color);
  padding: 0 15px;

  .ant-divider-horizontal {
    margin: 0 0 24px 0;
  }

  padding-bottom: 100px;

  scroll-behavior: smooth !important;
}

.footer {
  text-align: center;
  background-color: var(--footer-bg);
  position: fixed;
  bottom: 0;
  width: 100%;
}

.notification {
  .ant-menu-title-content {
    margin-inline-start: -5px !important;

    .anticon {
      vertical-align: -0.6rem;
    }
  }

  .notification-badge {
    vertical-align: top !important;
    margin-inline-start: 0 !important;
  }
}

.notification-data-item {
  .ant-list-item-meta-content {
    width: 100% !important;
  }
}

.ant-layout {
  // background-color: var(--app-container) !important;
}