@use '../../scss/index.scss' as *;
.auth {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--app-bg);
  &__container {
    @include tablet() {
      max-width: 999px;
    }
    max-width: 1250px;
    width: 100%;
    height: 80%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 25px rgb(0 0 0 / 7%);
    margin: 150px 0;
    background-color: #fff;
    overflow: hidden;
  }
  &__img {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 15px;
    // filter: brightness(1.5);
    background-color: #254267;
    &-info {
      text-align: center;
      margin-top: -15px;
      & > p {
        color: #fff;
        font-size: 1.55rem;
        opacity: 0.7;
        letter-spacing: 1.3px;
        margin-bottom: 5px;
      }
    }
    &-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      & > img {
        width: 350px;
        height: 350px;
        filter: brightness(1.5);
        object-fit: cover;
      }
    }
    &-contact {
      align-self: flex-start;
      color: #fff;
      opacity: 0.8;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 3px;
      letter-spacing: 1px;
    }
  }
  &__outlet {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #2a4a70;
    padding: 15px;
    &-ver {
      position: absolute;
      bottom: 15px;
      left: 15px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      color: #fff;
      opacity: 0.9;
      letter-spacing: 0.9px;
    }
  }
  &__row {
    height: 100%;
  }
}

//responsive
@media(max-height: 494px) {
  .auth {
    &__img {
      &-info {
        display: none;
      }
    }
    
  }
}

@media(max-height: 570px) {
  .auth {
    &__img {
      &-contact {
        display: none;
      }
    }
    &__outlet {
      &-ver {
        display: none;
      }
    }
  }
}

@media(min-height: 570px) and (max-height: 606px) {
  .auth {
    &__img {
      &-contact {
        display: none;
      }
    }
    &__outlet {
      &-ver {
        position: absolute;
      bottom: 50px;
      left: 15px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      color: #fff;
      opacity: 0.9;
      letter-spacing: 0.9px;
      }
    }
  }
}

@media  (min-height: 1100px){
  .auth {
    &__outlet {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
  }
}