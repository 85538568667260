@use '../../scss/breakpoint' as *;
@use '../../scss/inventory-common-variables' as *;
@use './mixes';

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  src:
    url(../../assets/fonts/inventory/Inter-Thin-BETA.woff2) format('woff2'),
    url(../../assets/fonts/inventory/Inter-Thin-BETA.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  src:
    url(../../assets/fonts/inventory/Inter-ThinItalic-BETA.woff2)
      format('woff2'),
    url(../../assets/fonts/inventory/Inter-ThinItalic-BETA.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  src:
    url(../../assets/fonts/inventory/Inter-ExtraLight-BETA.woff2)
      format('woff2'),
    url(../../assets/fonts/inventory/Inter-ExtraLight-BETA.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  src:
    url(../../assets/fonts/inventory/Inter-ExtraLightItalic-BETA.woff2)
      format('woff2'),
    url(../../assets/fonts/inventory/Inter-ExtraLightItalic-BETA.woff)
      format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  src:
    url(../../assets/fonts/inventory/Inter-Light-BETA.woff2) format('woff2'),
    url(../../assets/fonts/inventory/Inter-Light-BETA.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  src:
    url(../../assets/fonts/inventory/Inter-LightItalic-BETA.woff2)
      format('woff2'),
    url(../../assets/fonts/inventory/Inter-LightItalic-BETA.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src:
    url(../../assets/fonts/inventory/Inter-Regular.woff2) format('woff2'),
    url(../../assets/fonts/inventory/Inter-Regular.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  src:
    url(../../assets/fonts/inventory/Inter-Italic.woff2) format('woff2'),
    url(../../assets/fonts/inventory/Inter-Italic.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src:
    url(../../assets/fonts/inventory/Inter-Medium.woff2) format('woff2'),
    url(../../assets/fonts/inventory/Inter-Medium.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  src:
    url(../../assets/fonts/inventory/Inter-MediumItalic.woff2) format('woff2'),
    url(../../assets/fonts/inventory/Inter-MediumItalic.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src:
    url(../../assets/fonts/inventory/Inter-SemiBold.woff2) format('woff2'),
    url(../../assets/fonts/inventory/Inter-SemiBold.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  src:
    url(../../assets/fonts/inventory/Inter-SemiBoldItalic.woff2) format('woff2'),
    url(../../assets/fonts/inventory/Inter-SemiBoldItalic.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src:
    url(../../assets/fonts/inventory/Inter-Bold.woff2) format('woff2'),
    url(../../assets/fonts/inventory/Inter-Bold.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  src:
    url(../../assets/fonts/inventory/Inter-BoldItalic.woff2) format('woff2'),
    url(../../assets/fonts/inventory/Inter-BoldItalic.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  src:
    url(../../assets/fonts/inventory/Inter-ExtraBold.woff2) format('woff2'),
    url(../../assets/fonts/inventory/Inter-ExtraBold.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  src:
    url(../../assets/fonts/inventory/Inter-ExtraBoldItalic.woff2)
      format('woff2'),
    url(../../assets/fonts/inventory/Inter-ExtraBoldItalic.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  src:
    url(../../assets/fonts/inventory/Inter-Black.woff2) format('woff2'),
    url(../../assets/fonts/inventory/Inter-Black.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  src:
    url(../../assets/fonts/inventory/Inter-BlackItalic.woff2) format('woff2'),
    url(../../assets/fonts/inventory/Inter-BlackItalic.woff) format('woff');
}

body {
  &.inventory {
    * {
      font-family: 'Inter' !important;
      font-size: 14px;
      font-weight: 400;
      font-style: normal;
    }

    .inventory-top-nav-header-wide,
    .ant-page-header-wide,
    .ant-pro-grid-content-wide {
      max-width: 100%;
    }

    // Header
    .ant-page-header {
      .ant-page-header-breadcrumb {
        & + .ant-page-header-heading {
          margin-block-start: 0;

          @include mobile() {
            margin-top: 5px;
          }

          .ant-page-header-heading-left {
            display: none;
          }

          .ant-page-header-heading-extra {
            @include mobile() {
              overflow: scroll;
            }

            a {
              color: #166693;
              margin-right: 10px;

              &.bold {
                * {
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }

    // Layout header
    .ant-layout-header {
      &.inventory-layout-header {
        * {
          font-weight: bold;
        }
        height: 65px;
        line-height: 50px;

        .inventory-period {
          color: var(--light-color);
          margin-left: 20px;
          cursor: pointer;

          .ant-space-item {
            display: flex;
          }
        }

        @include mobile() {
          .inventory-period {
            display: none;
          }
        }
      }

      .log-out-header {
        color: var(--light-color);

        .log-out-icon {
          display: flex;
        }
      }

      .language-header {
        color: var(--light-color);
        height: 55px;

        .language-icon {
          display: flex;
        }
      }
    }

    // Breadcrumb
    .ant-breadcrumb {
      * {
        color: #262626;
      }
    }

    // Menu sub
    .ant-menu-sub {
      .inventory-menu-item {
        * {
          font-weight: bold;
        }
      }
      .inventory-menu-item-custom {
        font-weight: bold;
      }
    }

    // Sider
    .inventory-sider {
      @include mobile() {
        .inventory-period {
          display: none;
        }

        .inventory-period-footer-sp {
          color: var(--light-color);
          cursor: pointer;
        }
      }
    }

    // Sider menu
    .inventory-sider-menu {
      * {
        font-weight: 700;
      }
    }

    // Base menu
    .inventory-base-menu {
      .inventory-base-menu-item-icon {
        width: unset;
      }

      &.inventory-top-nav-header-base-menu {
        justify-content: center;
        align-items: center;
      }

      .inventory-base-menu-menu-item {
        height: 55px;
        line-height: 50px;
      }

      .inventory-base-menu-submenu {
        .inventory-submenu-item-space {
          display: flex;
        }

        .ant-menu-submenu-title {
          height: 55px;
          line-height: 50px;
        }
      }
    }

    // Menu light
    .ant-menu-light {
      &.ant-menu-submenu > .ant-menu {
        background-color: var(--inventory-main-color);
      }
    }

    // Global header right
    .ant-pro-global-header-right-content,
    .inventory-drawer-sider {
      .inventory-notification,
      .inventory-email-header {
        padding-inline: 15px;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 55px;

        .ant-badge-count {
          right: 50px !important;
          color: #166693;
          font-weight: bold;
          background-color: var(--light-color);
        }
      }
    }

    .ant-pro-global-header-header-actions-avatar {
      & > div {
        line-height: 50px;
        height: 55px;
      }
    }

    .inventory-base-menu-item-title {
      display: flex;
    }

    .ant-menu-submenu-popup {
      .arrow-down {
        display: none;
      }
    }

    // Footer
    footer {
      position: fixed;
      width: 100%;
      left: 0;
      bottom: 0;
      background-color: var(--inventory-main-color);
      color: var(--light-color);
      padding: 24px 20px;

      .footer-container {
        display: flex;
        justify-content: space-between;
      }
    }

    // Sider footer
    .inventory-sider-footer {
      footer {
        position: inherit;
        background-color: var(--inventory-main-color);
        color: var(--light-color);
        padding: 5px 10px;

        .footer-container {
          .footer-left {
            line-height: 2;
          }

          .footer-right {
            .ant-space {
              display: inherit;
              line-height: 2;
            }
          }
        }
      }
    }

    // Global header
    .inventory-global-header-collapsed-button {
      svg {
        color: var(--light-color);
      }
    }

    // Layout content
    .inventory-layout-content {
      .ant-pro-page-container {
        //background-color: var(--light-color);
        height: calc(100vh - 123px);

        .ant-pro-page-container-warp-page-header {
          padding-inline-start: 20px;
          padding-inline-end: 20px;
          background-color: var(--light-color);
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-block-start: 5px;
          padding-block-end: 5px;

          @include mobile() {
            display: block;
          }
        }

        .ant-pro-page-container-children-content {
          padding-inline: 20px;
          padding-block: 10px;
        }
      }
    }

    // Drawer sider
    .inventory-drawer-sider {
      .language-header {
        padding: 5px 10px;
        color: var(--light-color);
      }
    }

    // Common modal
    .ant-modal {
      .ant-modal-content {
        padding: 0 10px 10px 0;

        .ant-modal-close {
          top: 5px;
          inset-inline-end: 30px;
        }

        .ant-modal-header {
          border-bottom: 1px solid #dbdbdb;
          width: calc(100% - 15px);

          .ant-modal-title {
            background-image: linear-gradient(
              to top left,
              transparent 30%,
              var(--inventory-main-color) 0
            );
            color: var(--light-color);
            padding: 5px 20px;
            background-size: 20% 650%;
            background-repeat: no-repeat;
            background-position: left, right;
            height: 40.65px;

            @include mobile() {
              background-size: 80% 580%;
            }

            @include tablet() {
              background-size: 50% 580%;
            }

            * {
              font-weight: bold;
            }
          }
        }

        .ant-modal-body {
          padding: 20px 15px 20px 20px;

          .period-modal-description {
            .ant-descriptions-title {
              color: #9e9b9b;
            }
          }
        }

        .ant-modal-footer {
          padding: 15px 0 5px 20px;
          border-top: 1px solid #dbdbdb;
          margin: 0 15px 0 20px;
        }
      }
    }

    // Change period modal
    .change-period-modal {
      width: unset !important;

      .ant-modal-content {
        max-width: 600px;

        .ant-modal-header {
          .ant-modal-title {
            background-size: 50% 704%;
          }
        }

        .ant-modal-body {
          .ant-form-item {
            .ant-form-item-label {
              & > label {
                justify-content: flex-end;
                width: 120px !important;

                @include mobile() {
                  justify-content: flex-start;
                }
              }
            }
          }
        }
      }
    }

    button.link {
      border: none !important;
      background: none !important;
    }

    td {
      &.color-A74E0E {
        color: #a74e0e !important;
      }
    }

    .inventory-import-add-receipt-modal {
      &.ant-modal {
        width: calc(100% - 10vw) !important;
        height: 100%;
        margin-top: 4rem;
      }

      .ant-descriptions-item-content {
        .column {
          padding-right: 20px;
        }
      }

      .ant-pro-form-group {
        .has-explanation {
          .explanation {
            display: inline-flex;
            align-items: center;
            margin-bottom: 5px;
            padding-left: 10px;
          }
        }
      }
    }

    .add-export-voucher-modal,
    .add-import-voucher-modal {
      width: calc(100% - 10vw) !important;
      height: calc(100%) !important;
      margin-top: 4rem;

      .ant-pro-form {
        width: 100% !important;
        margin-top: 0;
        .ant-pro-form-group-container {
          width: 100%;

          .ant-space-item {
            width: inherit;

            .ant-select {
              .ant-select-selector {
                @include mixes.inventory-shadow-form-item();
              }
            }

            .ant-picker {
              width: 100%;
              @include mixes.inventory-shadow-form-item();
            }

            .ant-form-item-label {
              width: 15rem;
              text-align: right;

              @include mobile() {
                text-align: left;
              }

              .ant-form-item-required {
                float: right;

                @include mobile() {
                  float: left;
                }
              }
            }

            .ant-form-item-control {
              @include mobile() {
                padding: 0;
              }
            }

            label {
              color: #262626;
              line-height: 17px;
              justify-content: flex-end;

              &.ant-form-item-required {
                &:not(.ant-form-item-required-mark-optional) {
                  &::after {
                    content: ' (*)';
                    color: #a74e0e;
                    display: block;
                  }

                  &::before {
                    content: '' !important;
                  }
                }
              }

              @include mobile() {
                justify-content: flex-start;
              }
            }

            .ant-form-item
              .ant-form-item-label
              > label:not(.ant-form-item-required)::after {
              content: ' ' !important;
            }

            .ant-input-affix-wrapper {
              @include mixes.inventory-shadow-form-item();

              .ant-input {
                color: #0e0e0e !important;
              }
            }
          }
        }

        .ant-descriptions-item-container {
          .ant-descriptions-item-content {
            @include mobile() {
              display: block;
            }
          }
        }
      }

      .ant-modal-footer {
        display: flex;
        justify-content: flex-start;

        .save-btn {
          position: absolute;
          right: 0;
          bottom: 0;
          margin-right: 2rem;
          margin-bottom: 0.5rem;
        }
      }
    }

    .add-quantification-modal {
      width: calc(100% - 10vw) !important;
      height: calc(100%) !important;
      margin-top: 4rem;

      .ant-pro-form {
        width: 100% !important;
        margin-top: 0;
        .ant-pro-form-group-container {
          width: 100%;

          .ant-space-item {
            width: inherit;

            .ant-select {
              .ant-select-selector {
                @include mixes.inventory-shadow-form-item();
              }
            }

            .ant-picker {
              width: 100%;
              @include mixes.inventory-shadow-form-item();
            }

            .ant-form-item-label {
              width: 15rem;
              text-align: right;

              @include mobile() {
                text-align: left;
              }

              .ant-form-item-required {
                float: right;

                @include mobile() {
                  float: left;
                }
              }
            }

            .ant-form-item-control {
              padding: 0 1rem;

              @include mobile() {
                padding: 0;
              }
            }

            label {
              color: #262626;
              line-height: 17px;
              justify-content: flex-end;

              &.ant-form-item-required {
                &:not(.ant-form-item-required-mark-optional) {
                  &::after {
                    content: ' (*)';
                    color: #a74e0e;
                    display: block;
                  }

                  &::before {
                    content: '' !important;
                  }
                }
              }

              @include mobile() {
                justify-content: flex-start;
              }
            }

            .ant-input-affix-wrapper {
              @include mixes.inventory-shadow-form-item();

              .ant-input {
                color: #0e0e0e !important;
              }
            }
          }
        }

        .ant-descriptions-item-container {
          .ant-descriptions-item-content {
            @include mobile() {
              display: block;
            }
          }
        }

        .quantification-modal-information {
          .quantification-checkbox {
            width: 100%;
            flex-direction: row-reverse;
            justify-content: flex-start !important;

            @include mobile() {
              flex-direction: row;
            }

            &.deleted-checkbox,
            &.alcohol-checkbox {
              margin-top: 10px;
            }
          }

          .ant-select-selection-item {
            font-weight: bold;
          }

          .product-code {
            .ant-select-selection-item {
              color: red !important;
            }
          }

          #product-name {
            color: red !important;
            font-weight: bold;
          }
        }

        .quantification-modal-detail {
          .ant-descriptions-item-content {
            align-items: flex-start;
          }

          .quantification-price-summary {
            margin-top: 50px;

            strong {
              font-weight: bold !important;
            }

            .ant-form-item-control-input-content {
              text-align: center;

              @include mobile() {
                flex: unset;
              }
            }
          }
        }
      }

      .ant-modal-footer {
        display: flex;
        justify-content: flex-start;

        .save-btn {
          position: absolute;
          right: 0;
          bottom: 0;
          margin-right: 2rem;
          margin-bottom: 0.5rem;
        }
      }
    }

    .add-quantification-product-modal {
      width: calc(100% - 25vw) !important;
      height: calc(100%) !important;

      .ant-pro-form {
        width: 100% !important;
        margin-top: 0;

        .select-product {
          font-weight: normal;
        }
      }
    }

    // Form
    .inventory-form {
      padding: 10px;
      background-color: var(--light-color);

      label {
        color: #262626;
        line-height: 17px;
        justify-content: flex-end;

        &.ant-form-item-required {
          &:not(.ant-form-item-required-mark-optional) {
            &::after {
              content: ' (*)';
              color: #a74e0e;
              display: block;
            }

            &::before {
              content: '' !important;
            }
          }
        }

        @include mobile() {
          justify-content: flex-start;
        }
      }

      .ant-input-affix-wrapper {
        @include mixes.inventory-shadow-form-item();

        .ant-input {
          color: #0e0e0e !important;
        }
      }
    }

    .inventory-modal-form {
      .ant-form-item {
        margin-bottom: 5px;

        .ant-select {
          .ant-select-selector {
            @include mixes.inventory-shadow-form-item();
          }
        }

        .ant-picker {
          width: 100%;
          @include mixes.inventory-shadow-form-item();
        }
      }
    }

    // Button
    .inventory-button {
      &.ant-btn {
        border-radius: 10px;
        height: 40px;
        display: flex;
        align-items: center;

        &.ant-btn-primary {
          background-color: var(--inventory-main-color);
        }
      }
    }

    // Badge
    .inventory-badge {
      &.ant-badge {
        .ant-badge-count {
          margin-top: -8px;
          margin-right: -18px;
        }
      }
    }

    // Tag
    .inventory-tag {
      border-radius: 27px;
    }

    // Checkbox
    .ant-checkbox {
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          border: none;
          background-color: var(--light-color);

          &:after {
            border-color: var(--dark-color);
            inset-inline-start: 25%;
          }
        }
      }

      .ant-checkbox-inner {
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 2px;
        width: 20px;
        height: 20px;
      }
    }

    // Table
    .ant-pro-table {
      .ant-table-wrapper .ant-table-thead > tr > th,
      .ant-table-wrapper .ant-table-thead > tr > td {
        background-color: var(--light-color);
      }

      .ant-pro-card {
        .ant-pro-card-body {
          padding-inline: 0;
        }
      }
    }
  }
}
