@use '../../../scss/index.scss' as *;

.app {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px 24px;
    position: relative;
    height: 41px;
    background-color: var(--app-container);

    &-left,
    &-right {
      display: flex;
      align-items: center;
      position: relative;

      &__language {
        display: flex;
        align-items: center;
        gap: 3px;
        cursor: pointer;
        width: 80px;
        justify-content: flex-end;
        margin-left: 20px;

        &>img {
          width: 30px;
          height: 20px;
          object-fit: cover;
          margin-right: 3px;
        }

        &>span {
          color: var(--main-text-color);
          font-size: 13px;
          line-height: 24px;
          font-weight: 400;
        }

        &>svg {
          font-size: 1.1rem;
          margin-top: 3px;
          object-fit: cover;
          color: var(--main-text-color);
        }
      }

      &__sp {
        transition: all 0.1s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        //     cursor: pointer;
        &--ctn {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        // &:hover {
        //     transform: scale(1.1);
        // }
        &>a {
          text-decoration: none;
          display: flex;
          align-items: center;
          gap: 5px;

          & img {
            width: 15px;
            height: 15px;
            object-fit: cover;

            &.anydesk {
              width: 100px;
              height: 20px;
              object-fit: contain;
              transform: scale(1.5);
            }
          }

          &>span {
            color: #fff;
            font-size: 1.3rem;
          }
        }
      }
    }

    &-left {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }

    &__menu {
      &--right {
        border-bottom: unset;
        width: 100%;
        color: '#fff';
        font-weight: 'bold';
        background-color: '#fff';
        line-height: '32px';

        @include media(mobile, tablet) {
          width: 100%;
        }

        // & li {
        //     & :hover {
        //         color: #1677ff !important;
        //     }
        //     &::after {
        //         display: none !important;
        //     }

        //     & span,
        //     svg {
        //         color: var(--main-text-color) !important;
        //         // &:hover {
        //         //     color: #1677ff !important;
        //         // }
        //     }
        // }
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      & .ant-menu-horizontal {
        border-bottom: none !important;
      }

      & .ant-menu-submenu-title {
        &:hover {
          color: #1677ff !important;
        }
      }

      & .ant-menu-submenu::after {
        bottom: -6px !important;
        border-bottom-width: 3px !important;
      }

      & .ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal {
        &:hover {
          &::after {
            border-bottom-color: transparent !important;
          }
        }
      }

      & .ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-selected::after {
        border-bottom-color: #fff !important;
      }
    }

    &__banner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      filter: grayscale(100%);
      background-size: contain;
    }

    &__tab {
      opacity: 1;
      visibility: visible;
      transition: all 0.3s ease-in-out;
      display: flex;
      flex: 1;

      @include media(mobile, tablet) {
        opacity: 0;
        visibility: hidden;
        display: none;
      }
    }

    &__drawer {
      background-color: var(--app-container) !important;

      & .ant-drawer-body {
        padding: 0 !important;
      }

      & .ant-drawer-header {
        padding: 6px 24px !important;
      }
    }

    &__time {
      margin-right: 0px;
      min-width: 120px;

      &>span {
        color: #fff;
        font-size: 1.3rem;
        display: block;
        margin-top: 2px;
      }
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 1.5px;

    &>svg {
      color: #fff;
      font-size: 2.4rem;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }

    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    display: none;

    @include media(mobile, tablet) {
      opacity: 1;
      visibility: visible;
      display: block;
    }
  }

  &-name {
    color: var(--main-text-color);
    margin: 0;
    font-size: 2.2rem;
    line-height: 24px;
    font-weight: 700;
    margin: 0 30px 0 -5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    @include media(mobile, tablet) {
      margin: 0 30px 0 5px;
    }
  }
}

.header__item {
  display: flex;
  align-items: center;
  margin: 0 10px;

  &:hover {
    cursor: pointer;
  }

  &>p {
    font-size: medium;
    display: flex;
    align-items: center;

    &>span {
      color: var(--main-text-color);
    }

    &>svg {
      margin-top: 3px;
      font-size: 1.1rem;
      color: var(--main-text-color);
    }
  }

  &>a,
  span {
    gap: 5px;
    color: var(--main-text-color);
    margin: 0;
    line-height: 24px;
    padding: 0 0.75rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    text-decoration: none;
    border-radius: var(--border-radius-4);
    transition: background-color 0.1s ease;
    font-size: 1.4rem;
  }

  &>img {
    width: 30px;
  }
}

.profile-btn {
  padding: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding-left: 12px;

  img {
    width: 25px;
    height: 25px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 4px;
  }

  span {
    color: var(--main-text-color);
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }
}

.notify {
  position: absolute;
  top: 100%;
  right: -15px;
  width: 300px;
  min-height: 100px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fff;
  border-radius: 8px;
  z-index: 99;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  &__header {
    padding: 10px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;

    & h3 {
      color: var(--active-blue-color);
      font-size: 2rem;
      font-weight: 500;
    }
  }

  &__main {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
  }

  &__list {
    width: 100%;
    height: fit-content;
    // height: 500px;
    margin: 5px 0;
    padding: 0 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: var(--border-radius-8);
    transition: background 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }

    &-content {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      &>p {
        color: #000;
        font-size: 1.4rem;
      }

      &>span {
        color: var(--active-blue-color);
        font-size: 1.1rem;
      }
    }

    &--active {
      width: 10px;
      height: 10px;
      border-radius: 99px;
      background-color: #1677ff;
    }
  }
}

.app-header__tab {
  & .ant-menu-overflow {
    &>li {
      &>div {
        &:hover {

          &>svg,
          &>span {
            color: #1677ff !important;
          }
        }
      }
    }
  }
}

.changepw-modal {
  margin-top: 40px;

  .ant-form-item {
    margin-bottom: 10px !important;
  }
}

.header-menu {
  &__item {
    display: flex;
    align-items: center;
    gap: 10px;

    &>img {
      width: 20px;
      height: 20px;
      object-fit: cover;
    }

    &>span {
      color: #fff;
      font-size: 1.3rem;
      font-weight: 600;
    }
  }
}

.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub {
  background-color: var(--app-bg) !important;
  border-radius: 6px !important;
}

.ant-menu-submenu-popup {
  // bottom: 100% !important;
}

.ant-menu-item.ant-menu-item-active {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.ant-menu-light .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.ant-menu-title-content {
  color: #fff !important;
}

.ant-menu .ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  background-color: #fff !important;
}

.ant-drawer .ant-drawer-close {
  color: #fff !important;
}

.help-portal-modal {
  &.cs-modal {
    width: 95vw !important;
    top: 20px;
  }

  .ant-modal-content {
    padding: 8px 0 0 0;

    .cs-modal__body {
      height: 85vh;
    }
  }
}