.lock-screen {
  & .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  & .ant-form-item {
    margin-bottom: 0 !important;
  }
  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    &-avt {
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      width: 90px;
      height: 90px;
    }
    &-user {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      color: #fff;
      font-size: 2.4rem;
      font-weight: 600;
    }
    &-input {
      & > input {
        height: 30px;
        width: 250px;
        border-radius: 6px;
        resize: none;
        border: none;
        outline: none;
        color: white;
        padding: 5px;
        background-color: rgba(255, 255, 255, 0.1) !important;
        &::selection {
          outline: none !important;
        }
      }
    }
    &-options {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 5px;
      cursor: pointer;
      width: 250px;
      margin-top: 3px;
      &-item {
        width: 30px;
        height: 30px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.2);
        &:hover {
          background: rgba(255, 255, 255, 0.5);
        }
        &--active {
          background: rgba(255, 255, 255, 0.5);
        }
      }
    }
    &-numlock {
      width: 250px;
      height: 0;
      transition: all 0.2s linear;
      margin-top: 3px;
      border-radius: 6px;
      visibility: hidden;
      padding-top: 10px;
      opacity: 0;
      &--active {
        height: 300px;
        visibility: visible;
        opacity: 1;
      }
      &-item {
        width: 60px;
        height: 60px;
        // margin: 10px;
        font-size: 2em;
        border: 1px solid;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        line-height: 55px;
        margin: 0 auto;
        transition: all 0.2 ease-in-out;
        &:hover {
          background: rgba(200, 200, 200, 0.4);
          border-color: rgba(200, 200, 200, 0.4);
          cursor: pointer;
        }
      }
      &-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        &-item {
          width: 10px;
          height: 10px;
          padding: 5px;
          margin: 5px;
          border-radius: 50%;
          border: 1px solid #fff;
          display: block;
          // transition: all 0.1s ease-in-out;
          &--active {
            background-color: #fff;
          }
        }
      }
    }
  }
}

.vibration {
  animation: vibrate 0.5s;
}

@keyframes vibrate {
  from {
    margin-left: 0px;
    animation-timing-function: cubic-bezier(0.25, 0.01, 0.55, 0.16);
  }
  25% {
    margin-left: 29.3px;
    animation-timing-function: cubic-bezier(0.52, 0.44, 0.47, 0.44);
  }
  50% {
    margin-left: 0px;
    animation-timing-function: cubic-bezier(0.53, 0.56, 0.48, 0.56);
  }
  75% {
    margin-right: 29.3px;
    animation-timing-function: cubic-bezier(0.45, 0.84, 0.75, 0.99);
  }
  to {
    margin-right: 0px;
  }
}

// .ant-modal-mask{
//     transition: all 0.2s linear;
//     animation : goTop 0.2s !important
// }

// @keyframes goTop {
//     from{
//         transform: translateY(0);
//     }to{
//         transform: translateY(-100%);
//     }
// }
