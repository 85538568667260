//Mobile
@mixin mobile {
  @media (max-width: 739px) {
    @content;
  }
}

// Tablet
// @mixin tablet {
//     @media (min-width: 740px) and (max-width: 1023px) {
//         @content;
//     }
// }
@mixin tablet {
  @media (min-width: 740px) and (max-width: 1200px) {
    @content;
  }
}

@mixin large {
  @media (min-width: 1200px) {
    @content;
  }
}

// Tablet + Mobile
@mixin menma {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin medium {
  @media (max-width: 991px) {
    @content;
  }
}

// Report
@mixin report-extra-large {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin report-large {
  @media (min-width: 1100px) and (max-width: 1399px) {
    @content;
  }
}

@mixin report-large-under {
  @media (max-width: 1399px) {
    @content;
  }
}

@mixin report-medium {
  @media (min-width: 768px) and (max-width: 1099px) {
    @content;
  }
}

@mixin report-medium-under {
  @media (max-width: 1099px) {
    @content;
  }
}

@mixin report-small {
  @media (min-width: 576px) and (max-width: 767px) {
    @content;
  }
}

@mixin report-small-under {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin report-extra-small {
  @media (max-width: 575px) {
    @content;
  }
}

@mixin media($keys...) {
  @each $key in $keys {
    @if ($key ==mobile) {
      @include mobile {
        @content
      }
    }

    @else if ($key ==tablet) {
      @include tablet {
        @content
      }
    }

    @else if ($key ==medium) {
      @include medium {
        @content
      }
    }
  }
}