@use '../../../scss/index.scss' as *;
.footer {
  // padding: 10px 110px;
  padding: 10px 16px;
  background-color: #dbdbdb;
  transition: all 0.2s ease-in-out;
  z-index: 100;
  &.collapse {
    bottom: -100px;
    & .footer-collapse {
      & > div {
        transform: rotate(0deg);
      }
    }
  }
  &__item {
    display: flex;
    align-items: center;
    padding: 0 10px;
    background-color: #fff;
    border-radius: 6px;
    gap: 15px;
    height: 80px;
    @include tablet() {
      height: 85px;
    }
    & > img {
      width: 45px;
      height: 45px;
      object-fit: cover;
      @include tablet() {
        width: 40px;
        height: 40px;
      }
    }
    transition: all 0.2s ease-in-out;
    &:hover {
      //transform: scale(1.03);
      cursor: pointer;
    }
    &--active {
      background-color: #a1cdff9c !important;
    }
  }
  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-top: 5px;
    & > h3 {
      font-size: 1.7rem;
      font-weight: 500;
      @include tablet() {
        font-size: 1.6rem;
        margin-bottom: 3px;
      }
    }
    & > p {
      text-align: left;
      color: #767676;
      font-size: 1.35rem;
      @include tablet() {
        font-size: 1.2rem;
      }
    }

    &--footer-item {
      padding-right: 0 !important;
    }
  }
  &-collapse {
    position: absolute;
    top: -7px;
    width: 70px;
    height: 20px;
    background-color: #dbdbdb;
    left: 50%;
    transform: translateY(-50%);
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    z-index: 9;
    &:hover {
      cursor: pointer;
      transform: translateY(-65%);
    }
    &.active {
      .footer {
        bottom: 100px;
      }
    }
    & > div {
      transition: all 0.2s ease-in-out;
      transform: rotate(180deg);
    }
  }
}
