.global-spinner {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    font-size: 1.5rem;
    color: var(--active-blue-color);
  }
  & .ant-spin-blur::after {
    opacity: 0 !important;
  }
  .content {
    visibility: hidden;
  }
  & .ant-spin-text {
    text-shadow: unset !important;
  }
}
